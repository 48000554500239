//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CheckoutFooter',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};

